import { includes, get, snakeCase, isInteger, isArray, isNil } from 'lodash'
import pluralize from 'pluralize'
import parseURL from 'url-parse'
import queryString from 'query-string'

import { shiftBookingType } from 'common/constants'
import { isEntityNew, idForEntity } from 'api/schemas'
import UnsupportedEntityEndpointError from 'lib/errors/UnsupportedEntityEndpointError'
import { schemas } from './schemas'
import config from '../config'
import { View } from 'views/_client/features/SchedulePage/SchedulePage.types'

const defaultAvatarOptions = {
  width: '200',
  height: '200',
  crop: 'fill',
}

// List of actions (operations) on an entity type
export const apiActions = {
  list: 'list',
  get: 'get',
  create: 'create',
  update: 'update',
  delete: 'delete',
  sync: 'sync', // TODO Remove
}

export const methodForApiAction = {
  list: 'GET',
  get: 'GET',
  create: 'POST',
  update: 'PATCH',
  delete: 'DELETE',
}

/**
 * Base URL for the API.
 * Set an environment variable named REACT_APP_SYFT_API_URL to customize this per build.
 *
 * @type {string}
 */
export const API_BASE = config.API_URL

// Query params serialization is based on format consumed by Rails / Syft API:
// - support serialization of nested objects / arrays
// - differentiate between null and empty string
// - don't use array indices (a[1]=x becomes a[]=x)
// Note that empty objects and empty arrays will be serialized as null.
// https://syftapp.slack.com/archives/G71V7Q481/p1516868553000037
const qsOpts = { arrayFormat: 'bracket', skipNull: false }
export const parseSyftQueryString = string => queryString.parse(string, qsOpts)
export const stringifyIntoSyftQueryString = obj => queryString.stringify(obj, qsOpts)

/**
 * Makes an URL to API endpoint from path and query object.
 *
 * @param path API path to make a call to
 * @param args Key/value pairs to be sent in query string
 * @returns {String} absolute URL to API endpoint
 */
export const apiURL = (path, args = {}) => {
  // Exposed for storybook. See .storybook/mockImages
  if (window.__storybook__apiURL) {
    const result = window.__storybook__apiURL(path, args)
    if (result) return result
  }

  const API = args.use_v3_endpoint ? API_BASE.replace('/api/v2', '/api/v3') : API_BASE

  // `path` can already contain some query params
  const url = parseURL(API + path, false)

  const existingQueryString = url.query && url.query.replace(/^\?/, '')
  const existingQuery = parseSyftQueryString(existingQueryString)
  const newQuery = stringifyIntoSyftQueryString({ ...existingQuery, ...args })
  url.query = newQuery

  return url.toString()
}

/**
 * Makes an image URL from image id and options object.
 *
 * @param id uuid of the image
 * @param options Key/value pairs to be sent in query string
 * @returns {String} absolute URL to image
 */
export const imageURL = (id, options = {}) => apiURL(`/images/${id}`, options)

/**
 * Makes an image URL from image id and options object. Merges options
 * with default avatar options (size and crop)
 *
 * @param id uuid of the image
 * @param options Key/value pairs to be sent in query string
 * @returns {String} absolute URL to image
 */
export const avatarURL = (id, opts = {}) => imageURL(id, { ...defaultAvatarOptions, ...opts })

// TODO Remove, generate from associations
// @param {String} subath
const endpointWithEmployerBasePath = subpath => meta => {
  // `meta` should contain context with userData passed down from sagas/index
  // in entityCall
  const employerId = get(meta, 'userData.employerId') || get(meta, 'employerId')
  return { url: `/employers/${employerId}${subpath}` }
}

// @param {String} subath
const endpointWithAgencyBasePath = (subpath, withoutAgencyPortal) => meta => {
  // `meta` should contain context with userData passed down from sagas/index
  // in entityCall
  const agencyId = get(meta, 'userData.agencyId')
  if (withoutAgencyPortal) {
    return { url: `/agencies/${agencyId}${subpath}` }
  }
  return {
    url: `/agency_portal/agencies/${agencyId}${subpath}`,
  }
}

const endpointWithEmployerTimesheetBasePath = subpath => ({
  url: `/employers/timesheet/${subpath}`,
})

const endpointWithAgencyTimesheetBasePath = subpath => {
  return subpath
    ? { url: `/agency_portal/timesheets/entries/${subpath}`, doesNeedId: false }
    : { url: '/agency_portal/timesheets/entries' }
}

/**
 * Return bool if the user a SyftForce user
 * TODO: change isInternalResourcing -> isSyftForceUser
 */
const getInternalResourcingStatus = meta => get(meta, 'userData.platform.internalResourcing') === true

// Configuration for endpoints that deviate from our standard endpoint format
// (as defined by `getEntityEndpoint` below).
//
// Defaults are based on http://guides.rubyonrails.org/routing.html#crud-verbs-and-actions
// See also http://jsonapi.org
//
// TODO Improve validation of required params
const customEndpoints = {
  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/8224801/My+Info+API
  currentUser: entity => ({ url: '/users/me', doesNeedId: false }),

  listing: (entity, meta, apiAction) => {
    return includes([apiActions.delete, apiActions.update], apiAction)
      ? '/listings'
      : endpointWithEmployerBasePath('/listings')(meta)
  },

  approveListing: (entity, meta) => endpointWithEmployerBasePath('/authorisation/listings')(meta),

  listingOverview: (entity, meta) => {
    return {
      ...endpointWithEmployerBasePath(`/listings/${entity.id}/overview`)(meta),
      doesNeedId: false,
    }
  },

  listingReason: (entity, meta) => {
    const employerId = get(meta, 'userData.employerId')
    const listingId = get(meta, 'listingId')
    return {
      url: `/employers/${employerId}/listings/${listingId}/approval_statuses`,
      method: 'POST',
      doesNeedId: false,
    }
  },

  listingTemplate: (entity, meta, apiAction) => {
    return includes([apiActions.list, apiActions.create], apiAction)
      ? endpointWithEmployerBasePath('/listing_templates')(meta)
      : '/listing_templates'
  },

  shiftPattern: (entity, meta) => {
    const employerId = get(meta, 'userData.employerId')
    return { url: `/employers/${employerId}/listings/shift_patterns` }
  },

  agencyTimesheet: (entity, meta, apiAction) => {
    if (apiAction === apiActions.update) {
      if (entity.noShowReasonId) return { ...endpointWithAgencyTimesheetBasePath(`${entity.id}/no_show`) }
      return { ...endpointWithAgencyTimesheetBasePath(entity.id) }
    }
    return endpointWithAgencyTimesheetBasePath()
  },

  agencyTimesheetCount: (entity, meta, apiAction) => {
    if (apiAction === apiActions.update) {
      return endpointWithAgencyTimesheetBasePath('submit_all')
    }
    return endpointWithAgencyTimesheetBasePath('count')
  },

  agencyTimesheetWorker: () => {
    return { ...endpointWithAgencyTimesheetBasePath('worker_names') }
  },

  agencyWorker: (_entity, meta, apiAction) => {
    if (apiAction === apiActions.update) {
      return { url: '/agency_portal/workers' }
    }

    return endpointWithAgencyBasePath('/workers')(meta)
  },

  employerAccount: (entity, meta, apiAction) => {
    return endpointWithEmployerBasePath('/employer_accounts')(meta)
  },

  permission: (entity, meta, apiAction) => {
    return includes([apiActions.list, apiActions.create], apiAction)
      ? endpointWithEmployerBasePath('/employer_accounts/permissions')(meta)
      : '/employer_accounts/permissions'
  },

  listingPublication: entity => {
    // Endpoints will be deprecated and consolidated to use listingPublish
    // SF posting to Syft
    const publishToSyftFromSFUrl = `/syft_posting/listings/${entity.id}/publish`
    // Syft posting to Syft or SF posting to SF
    const publishToOwnPlatformUrl = `/listings/${entity.id}/publish_with_payment`

    // SLUG-135 post to agency has its own endpoint
    const url =
      entity.offerTo === 'agency'
        ? `/listings/${entity.id}/publish_to_agency`
        : entity.syftPosting
        ? publishToSyftFromSFUrl
        : publishToOwnPlatformUrl

    return { method: 'PUT', url, doesNeedId: false }
  },

  listingPublish: entity => {
    const url = `/listings/${entity.id}/publish_and_offer`
    return { method: 'PUT', url, doesNeedId: false }
  },

  listingRole: entity => {
    const url = `/listings/${entity.listingId}/roles`
    return { url, doesNeedId: false }
  },

  schedule: (entity, meta) => ({
    url: `/employers/${get(meta, 'userData.employerId')}/listings/schedule/${
      meta.viewMode === 'Week' ? 'weekly' : 'daily'
    }`,
  }),
  scheduleMonth: (entity, meta) => {
    return {
      url: `/employers/${get(meta, 'userData.employerId')}/listings/schedule/monthly`,
    }
  },
  insight: (entity, meta) => {
    let viewEndpoint = 'daily'
    if (meta.viewMode === View.Week) viewEndpoint = 'weekly'
    if (meta.viewMode === View.Month) viewEndpoint = 'monthly'
    return {
      url: `/employers/${get(meta, 'userData.employerId')}/listings/schedule/insights/${viewEndpoint}`,
      doesNeedId: false,
    }
  },
  scheduleAll: (entity, meta) => {
    return {
      url: `/employers/${get(meta, 'userData.employerId')}/listings/schedule/all`,
    }
  },

  workLocation: (entity, meta) => endpointWithEmployerBasePath('/work_locations')(meta),

  venue: (entity, meta, apiAction) => {
    const endpoint = endpointWithEmployerBasePath('/venues')(meta)
    return {
      ...endpoint,
      ...(apiAction === 'update' ? { method: 'PUT' } : {}),
    }
  },

  venuesWithArea: () => {
    return {
      url: '/employers/work_locations/venues_with_areas',
      doesNeedId: false,
    }
  },

  lightVenue: (entity, meta, apiAction) => {
    const endpoint = endpointWithEmployerBasePath('/venues_for_filters')(meta)
    return {
      ...endpoint,
      doesNeedId: false,
    }
  },

  overviewVenue: (entity, meta, apiAction) => {
    const endpoint = endpointWithEmployerBasePath('/venues/overview')(meta)
    return {
      ...endpoint,
      doesNeedId: false,
    }
  },

  // `entity` must contain venueId
  area: (entity, meta, apiAction) => {
    const { url: venuesUrl } = endpointWithEmployerBasePath('/venues')(meta)
    return {
      url: `/${venuesUrl}/${entity.venueId}/areas`,
      ...(apiAction === 'update' ? { method: 'PUT' } : {}),
    }
  },

  wagePreview: (entity, meta, apiAction) => {
    const isInternalResourcing = getInternalResourcingStatus(meta)
    // Use post method when a job is posting to syft straight from FLex+.
    // Using this new option method to retrieve the needed data for the confirmation page
    // https://syftapp.atlassian.net/browse/ORA-481
    if (!entity.option && isInternalResourcing) {
      // Wage preview for a Syft posting – listing posted to Syft platform
      // instead of internally.
      // This assumes that IR never shows wage previews other than for Syft postings
      //
      // https://syftapp.atlassian.net/wiki/spaces/SV/pages/231014465/Syft+Posting+Wage+Preview+API
      if (entity.id) {
        return {
          doesNeedId: false,
          // Wage preview is identified by listingId
          url: `${meta.isEditingEntity ? '' : '/syft_posting'}/listings/${entity.id}/wage_preview`,
        }
      } else {
        throw new UnsupportedEntityEndpointError('wagePreview', apiAction)
      }
    } else {
      // Wage preview a listing.
      //
      // We're sending a full listing entity in this endpoint. For sake of consistency
      // of the wage preview with POST /listings, we want to serialize the listing here
      // as JSON. With GET request we would need to serialize the listing
      // as query params.
      //
      // https://syftapp.atlassian.net/wiki/spaces/SV/pages/21299282/Wage+Preview+API
      const { url } = endpointWithEmployerBasePath('/wage_preview')(meta)
      return {
        doesNeedId: false,
        method: 'POST',
        url,
      }
    }
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/782565380/Employer+facing+API+Proposal
  listingAgency: (entity, meta, apiAction) => {
    if (apiAction === apiActions.list) {
      return { url: `/internal_resourcing/listings/${entity.listingId}/agencies` }
    } else if (apiAction === apiActions.get) {
      return {
        url: `/internal_resourcing/listings/${entity.listingId}/agencies/${entity.agencyId}`,
        doesNeedId: false,
      }
    } else if (apiAction === apiActions.delete) {
      return {
        url: `/internal_resourcing/listings/${entity.listingId}/agencies/${entity.id}`,
        doesNeedId: false,
      }
    }
  },

  country: ({ id }, meta, apiAction) => {
    return {
      url: apiAction === apiActions.get ? `/countries/${id}` : '/countries',
      doesNeedId: false,
    }
  },

  authLoginType: (entity, { profileType = 'employer' }) => {
    return {
      url: `/indeed_auth/login_type?email=${encodeURIComponent(
        entity.id || entity.email,
      )}&profile_type=${profileType}`,
      doesNeedId: false,
      useBodyAsQueryParams: true,
    }
  },

  listingAgencyEmail: (entity, meta, apiAction) => {
    if (apiAction === apiActions.create) {
      return {
        url: `/internal_resourcing/listings/${entity.listingId}/agencies/${entity.agencyId}/send_allocation_email`,
      }
    }
  },

  // https://github.com/Syft-Application/syft-api-specs/blob/master/agency-portal/employer-facing.md
  agencyShift: (entity, meta, apiAction) => {
    const deleteWholeAllocation = get(meta, 'deleteWholeAllocation', false)
    const manageAgencyAllocation = get(meta, 'manageAgencyAllocation', false)
    const acceptAllocations = get(meta, 'acceptAllocations', false)
    if (acceptAllocations) {
      return { url: '/agency_portal/agency_jobs', doesNeedId: false }
    }
    if (apiAction === apiActions.list) {
      return {
        url: `/internal_resourcing/listings/${entity.listingId}/agencies/${entity.agencyId}/agency_shifts`,
      }
    } else if (apiAction === apiActions.delete && deleteWholeAllocation) {
      return {
        url: `/internal_resourcing/listings/${entity.listingId}/agencies/${entity.agencyId}`,
        doesNeedId: false,
      }
    } else if (manageAgencyAllocation) {
      return { url: '/agency_portal/bulk_agency_shifts', doesNeedId: false }
    }
    return { url: '/internal_resourcing/bulk_agency_shifts', doesNeedId: false }
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/135200914/Agencies+API
  agency: entity => ({
    url: `/internal_resourcing/agencies`,
  }),

  agencyShiftWorker: (entity, meta) => {
    return { url: '/internal_resourcing/agency_shift_workers' }
  },

  nonSyftExperience: (entity, meta, apiAction) => {
    return {
      url: `/non_syft_experiences/referee/${entity.id}`,
      doesNeedId: false,
    }
  },

  jobCandidate: ({ jobId, shiftId, status }, meta, apiAction) => {
    const isInternalResourcing = getInternalResourcingStatus(meta)
    if (meta.url) {
      // Next-batch pagination support
      return { url: meta.url }
    } else if (!isNil(shiftId)) {
      if (isInternalResourcing) {
        return { url: `/internal_resourcing/shifts/${shiftId}/candidates/${status}` }
      }
      // https://syftapp.atlassian.net/wiki/display/SV/Candidates+API
      return { url: `/shifts/${shiftId}/candidates/${status}` }
    } else {
      // https://syftapp.atlassian.net/wiki/display/SV/Search+Job+Candidates+API
      return { url: `/search/workers/by_job/${jobId}` }
    }
  },

  jobId: () => {
    return { url: `/agency_portal/job_ids`, doesNeedId: false }
  },

  jobRate: (payload, meta) => {
    const employerId = get(meta, 'userData.employerId')
    return { url: `/employers/${employerId}/job_creation_config`, doesNeedId: false }
  },

  userChatVerification: (payload, meta) => {
    return { url: `/salesforce/user_verification_token`, doesNeedId: false }
  },

  standardUniform: () => ({ url: '/standard_uniform_images' }),

  employerTimesheetRating: (payload, meta, apiAction) => {
    return { ...endpointWithEmployerTimesheetBasePath(`${payload.timesheetId}/rating`), doesNeedId: false }
  },

  // https://syftapp.atlassian.net/wiki/display/SV/Rating+API+module
  // TODO Rating identified by jobId+workerId
  rating: (payload, meta, apiAction) => {
    // TODO Refactor
    const entity = isArray(payload) ? payload[0] : payload
    const { jobId, listingId, workerId } = entity

    if (apiAction === apiActions.list) {
      return { url: `/listings/${listingId}/ratings` }
    } else if (isArray(payload)) {
      return { url: `/jobs/${jobId}/ratings`, doesNeedId: false, method: 'PUT' }
    } else if (isInteger(workerId)) {
      return { url: `/jobs/${jobId}/workers/${workerId}/rating`, doesNeedId: false, method: 'PUT' }
    } else {
      return { url: `/listings/${listingId}/ratings`, doesNeedId: false, method: 'PUT' }
    }
  },

  employerTimesheet: (entity, meta, apiAction) => {
    if (apiAction === apiActions.create || apiAction === apiActions.update) {
      return { ...endpointWithEmployerTimesheetBasePath('approve'), doesNeedId: false }
    }
    return endpointWithEmployerTimesheetBasePath('entries')
  },

  employerTimesheetsApproveAll: (_entity, _meta, apiAction) => {
    const endpoint = endpointWithEmployerTimesheetBasePath('approve_all')
    if (apiAction === apiActions.create) return endpoint

    return { ...endpoint, doesNeedId: false }
  },

  employerTimesheetsApproval: (_entity, _meta, apiAction) => {
    if (apiAction === apiActions.create) return endpointWithEmployerTimesheetBasePath('approve')

    return null
  },

  employerTimesheetEntry: () => endpointWithEmployerTimesheetBasePath('entries'),

  employerTimesheetProvisionalCost: () => ({
    ...endpointWithEmployerTimesheetBasePath('provisional_cost'),
    doesNeedId: false,
  }),

  timesheet: (entity, meta, apiAction) => {
    // Used for bulk update
    // Update timesheet (entries.needsApproval etc.)
    // https://syftapp.atlassian.net/wiki/pages/viewpage.action?pageId=3735607
    if (apiAction === apiActions.update && get(entity, 'entries')) {
      return { url: '/timekeeping/bulk_approve_with_agency', doesNeedId: false, method: 'PUT' }
    }
    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/65372183/Timesheet+Approve+All+API
    else if (apiAction === apiActions.update) {
      // Update timesheet (entries.needsApproval etc.)
      // https://syftapp.atlassian.net/wiki/pages/viewpage.action?pageId=3735607
      return { url: '/timekeeping/approve_all_with_agency', doesNeedId: false, method: 'PUT' }
    }
    // https://syftapp.atlassian.net/wiki/display/SV/Get+Timesheet
    else {
      return { url: `/listings/${entity.listingId}/timesheet_entries_with_agency`, doesNeedId: false }
    }
  },

  timesheetEntry: ({ listingId }, meta, apiAction) => {
    // https://syftapp.atlassian.net/wiki/display/SV/Get+Timesheet
    return { url: `/listings/${listingId}/timesheet_entries_with_agency`, doesNeedId: false }
  },

  timesheetAgencyEntry: ({ agencyId }, meta, apiAction) => {
    // https://www.notion.so/syftapp/Agency-Portal-Timesheet-633f5b9d439c401e9ca33d8decc12cc3#e6caf01362534f2eb839617257714f22
    return { url: `/agency_portal/agencies/${meta.userData.agencyId}/timesheets`, doesNeedId: false }
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/65011735/Timesheet+Listing+Approval+Stats+API
  timesheetApprovalStat: ({ listingId }) => {
    return { url: `/listings/${listingId}/timesheet_approval_stats_with_agency`, doesNeedId: false }
  },

  // https://www.notion.so/syftapp/Listing-authorization-0fdd0a717541446a8f88eda4f77fa7a5#cc7e5b9dd39c471793a776c067283ca7
  listingApprovalDetail: ({ listingId }) => {
    return { url: `/listings/${listingId}/approver_details`, doesNeedId: false }
  },

  timesheetProvisionalCost: ({ id }) => {
    return { url: `/listings/${id}/provisional_cost`, doesNeedId: false }
  },

  // Create timesheet email
  // https://syftapp.atlassian.net/wiki/display/SV/Send+Timesheet+By+Email
  timesheetEmail: ({ jobId, listingId }) => {
    if (isInteger(listingId)) {
      return { url: `/listings/${listingId}/email_timesheet` }
    } else if (isInteger(jobId)) {
      return { url: `/jobs/${jobId}/email_timesheet` }
    } else {
      return null
    }
  },

  noShowReason: () => ({
    doesNeedId: false,
    url: '/no_show_reasons',
  }),

  booking: (entity, meta, apiAction) => {
    if (apiAction === apiActions.delete) {
      return { url: '/bookings/delete', doesNeedId: false, method: 'PUT' }
    }
  },

  rota: ({ venueId }, { userData }) => {
    const employerId = get(userData, 'employer.id')
    return {
      url: `/employers/${employerId}/venues/${venueId}/rotas`,
      doesNeedId: false,
    }
  },

  rotaPublish: (_, { userData, rotaId }) => {
    const employerId = get(userData, 'employer.id')
    return {
      url: `/employers/${employerId}/rota/${rotaId}/publish`,
      method: 'POST',
    }
  },

  rotaCopy: (_, { userData, venueId }) => {
    const employerId = get(userData, 'employer.id')
    return {
      doesNeedId: false,
      method: 'POST',
      url: `/employers/${employerId}/venues/${venueId}/rota/copy`,
    }
  },

  removeWorkerFromAllocation: (entity, meta) => {
    return meta?.isBookableIndividually
      ? { url: '/agency_portal/remove_shift_workers', doesNeedId: false }
      : { url: '/agency_portal/remove_shift_workers_by_name', doesNeedId: false }
  },

  multipleBooking: (_, { userData, bookingType }) => {
    const employerId = get(userData, 'employer.id')
    if (bookingType === shiftBookingType.soft) {
      return {
        doesNeedId: false,
        method: 'POST',
        url: `/employers/${employerId}/rota/soft_bookings`,
      }
    }
    if (bookingType === shiftBookingType.offer) {
      return {
        doesNeedId: false,
        method: 'PUT',
        url: '/bookings/multiple_jobs_offer',
      }
    }
    return {
      doesNeedId: false,
      method: 'PUT',
      url: '/bookings/multiple_create',
    }
  },

  employerBulkBreak: () => {
    return {
      doesNeedId: false,
      method: 'POST',
      url: '/timekeeping/employer_bulk_breaks',
    }
  },

  cancelBooking: (_, { userData, isSoftBooking }) => {
    if (isSoftBooking) {
      const employerId = get(userData, 'employer.id')
      return {
        doesNeedId: false,
        method: 'DELETE',
        url: `/employers/${employerId}/rota/soft_bookings`,
      }
    }
    return {
      doesNeedId: false,
      method: 'PUT',
      url: '/bookings/cancel',
    }
  },

  // https://syftapp.atlassian.net/wiki/display/SV/Booking+Actions+API
  bulkOffer: ({ workerIds }) => {
    return {
      doesNeedId: false,
      method: 'PUT',
      url: `/bookings/${workerIds ? 'bulk_offer' : 'bulk_offer_applied_workers'}`,
    }
  },

  // https://syftapp.atlassian.net/wiki/display/SV/Worker%27s+Employer-Facing+Profile+API
  // https://syftapp.atlassian.net/wiki/display/SV/Browse+Workers+API
  // https://syftapp.atlassian.net/wiki/display/SV/Trusted+Network+API
  //
  // List action query params:
  //   * name (optional) – filter by name
  //   * role_id (optional) – filter by role
  //   * trusted (optional) – false to exclude trusted workers
  //
  // Internal resourcing employers are authorized to use admin endpoints.
  //
  // TODO If you're adding Worker Private Profile (/workers/:worker_id/private_profile),
  // you should merge it with Employer-facing Profile
  worker: (entity, meta, apiAction) => {
    const employerId = get(meta, 'userData.employerId')
    const blacklistEndpoint = endpointWithEmployerBasePath('/blacklisted_workers')(meta)
    // Instruction to use admin endpoint which contains worker's EUN and pay_rate
    const isInternalResourcing = getInternalResourcingStatus(meta)
    const useSyftForceWorkerEndpoint = get(
      meta,
      'useSyftForceWorkerEndpoint',
      isInternalResourcing && !get(meta, 'useNewSyftForceWorkerEndpoint'),
    )
    const useNewSyftForceWorkerEndpoint = get(meta, 'useNewSyftForceWorkerEndpoint')
    const useTrustedNetworkEndpoint = get(meta, 'useTrustedNetworkEndpoint')
    const useTrustedNetworkLightEndpoint = get(meta, 'useTrustedNetworkLightEndpoint')
    const trustedNetworkWorkersEndpoint = endpointWithEmployerBasePath('/trusted_network_workers')(meta)
    const trustedNetworkWorkersLightEndpoint = endpointWithEmployerBasePath('/trusted_network_workers/light')(
      meta,
    )
    const isFetchingOrUpdatingBlacklist = get(meta, 'useBlacklistApi')
    const isForSyftWorkers = get(meta, 'useSyftWorkersEndpoint')

    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/224198686/Employer+Blacklist+API
    if (isFetchingOrUpdatingBlacklist) {
      if (apiAction === apiActions.update) {
        return {
          ...endpointWithEmployerBasePath('/blacklists/manage_worker')(meta),
          method: 'PUT',
        }
      }
      return blacklistEndpoint
    }
    // By default we use /admin/workers/${entity.id}/profile on IR, unless
    // otherwise requested through meta flags
    // TODO Refactor (this was the least invasive change)
    else if (apiAction === apiActions.get) {
      return {
        doesNeedId: false,
        url: `/workers/${entity.id}/profile_for_employer/${employerId}`,
      }
    }
    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/428408833/Workers+API
    else if (apiAction === apiActions.update && useNewSyftForceWorkerEndpoint) {
      return {
        doesNeedId: false,
        url: `/internal_resourcing/workers/${entity.id}/profile`,
      }
    }
    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/31002741/Admin+Edit+Worker+s+Profile+API
    else if (apiAction === apiActions.update && useSyftForceWorkerEndpoint) {
      return {
        doesNeedId: false,
        url: `/admin/workers/${entity.id}/profile`,
      }
    }
    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/17629188/Trusted+Network+API
    else if (apiAction === apiActions.update && useTrustedNetworkEndpoint) {
      return {
        ...trustedNetworkWorkersEndpoint,
        method: 'PUT',
      }
    }
    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/17629188/Trusted+Network+API
    else if (apiAction === apiActions.list && useTrustedNetworkLightEndpoint) {
      return trustedNetworkWorkersLightEndpoint
    } else if (apiAction === apiActions.list && useTrustedNetworkEndpoint) {
      return trustedNetworkWorkersEndpoint
    }
    // https://www.notion.so/syftapp/Bookings-Syft-Workers-f2472e942c9041f2bc5d8a0cc40596f4
    else if (apiAction === apiActions.list && isForSyftWorkers) {
      return { url: '/non_network_workers/bookings' }
    }
    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/52723713/Delete+IR+Workers+API
    else if (apiAction === apiActions.delete && useSyftForceWorkerEndpoint) {
      return {
        url: `/internal_resourcing/workers`,
      }
    } else if (apiAction === apiActions.list) {
      return { url: `/search/workers/by_employer/${employerId}` }
    }
    return null
  },

  workerName: () => {
    return { url: '/employers/timesheet/worker_names' }
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/40206340/Invite+Worker+API
  internalWorkerInvitation: (entity, meta, apiAction) => {
    if (apiAction === apiActions.list || apiAction === apiActions.delete) {
      return { url: '/internal_resourcing/imported_workers' }
    }
    return { url: '/internal_resourcing/invite_worker' }
  },

  // https://www.notion.so/syftapp/Workers-experiences-a262c09c025a46b2bcf9358289ea45b3
  workerExperience: (entity, meta, apiAction) => {
    if (apiAction === apiActions.list) {
      return {
        url: `/workers/${entity.workerId}/experiences`,
      }
    } else {
      throw new UnsupportedEntityEndpointError('workerExperience', apiAction)
    }
  },

  workerSignupInvitation: (entity, meta) => {
    return {
      url: `/internal_resourcing/workers/invitation/${entity.inviteToken}?regenerate_if_expired=${
        meta.regenerateIfExpired ? 'true' : 'false'
      }`,
      doesNeedId: false,
    }
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/18284563/Admin+Update+Worker+Role+API
  workerRole: (entity, meta, apiAction) => {
    if (includes([apiActions.create, apiActions.update, apiActions.delete], apiAction)) {
      return {
        url: `/admin/workers/${entity.workerId}/roles/${entity.roleId}`,
        ...(apiAction === apiActions.update ? { method: 'PUT' } : {}),
        doesNeedId: false,
      }
    } else {
      throw new UnsupportedEntityEndpointError('workerRole', apiAction)
    }
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/94470191/Manage+Venues+Areas+for+IR+Worker
  workerVenue: (entity, meta, apiAction) => {
    const employerId = get(meta, 'userData.employerId')
    if (apiAction === apiActions.list) {
      return {
        doesNeedId: false,
        url: `/workers/${entity.workerId}/worker_venues_for_employer/${employerId}`,
      }
    }
    return {
      doesNeedId: false,
      url: `/admin/workers/${entity.workerId}/worker_venues`,
    }
  },

  workerTrustedWorkLocation: (entity, meta, apiAction) => {
    const employerId = get(meta, 'userData.employerId')

    if (apiAction === apiActions.list) {
      return {
        doesNeedId: false,
        url: `/workers/${entity.workerId}/worker_trusted_work_locations_for_employer/${employerId}`,
      }
    }
    throw new UnsupportedEntityEndpointError('workerTrustedWorkLocation', apiAction)
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/31468240/Worker+s+Employer-facing+Past+Jobs
  workerJob: (entity, meta, apiAction) => {
    if (apiAction === apiActions.list) {
      return {
        url: `/workers/${entity.workerId}/past_jobs`,
      }
    } else {
      throw new UnsupportedEntityEndpointError('workerJob', apiAction)
    }
  },

  // https://syftapp.atlassian.net/wiki/display/SV/Signup+API
  // TODO Supports only apiActions.create
  user: entity => {
    if (entity.inviteToken) {
      return { url: '/internal_resourcing/workers/accept_invitation' }
    } else if (entity.resetPasswordToken) {
      return { url: '/users/account_activation', method: 'PUT', doesNeedId: false }
    } else if (entity.resendToken) {
      return { url: '/users/account_activation', method: 'POST', doesNeedId: false }
    } else {
      return { url: '/users/registration' }
    }
  },

  // https://syftapp.atlassian.net/wiki/display/SV/Employer+Signup+API
  employer: (entity, meta, apiAction) => {
    if (apiAction === apiActions.create) {
      return { url: '/employers/signup_with_payment' }
    } else {
      return { url: '/employers' }
    }
  },

  // https://www.notion.so/syftapp/API-f5f22c454bd646edb28831ed5ff0d9b7
  suggestion: (entity, meta) => {
    return {
      url: `/employers/${meta.userData.employerId}/fulfilment_suggestions`,
      method: 'POST',
      doesNeedId: false,
    }
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/161447937/Employer+Account+Invitation+Api+draft
  employerAccountInvitation: ({ token }, meta, apiAction) => {
    if (apiAction === apiActions.get) {
      return { url: `/employer_account_invitation?token=${token}`, doesNeedId: false }
    } else if (apiAction === apiActions.create && get(meta, 'useRegenerateEndpoint')) {
      const endpoint = endpointWithEmployerBasePath('/employer_accounts')(meta)
      return {
        ...endpoint,
        url: `${endpoint.url}/regenerate_invitation_token`,
        doesNeedId: false,
      }
    } else {
      return { url: `/employer_account_invitation`, doesNeedId: false }
    }
  },

  // https://syftapp.atlassian.net/wiki/display/SV/Messaging+API+for+Clients
  thread: (entity, meta, apiAction) => {
    const endpoint = endpointWithEmployerBasePath('/conversations')(meta)
    // Mark conversation as read
    // PUT /employers/_/conversations/_/mark_read
    if (apiAction === apiActions.update && typeof entity.read === 'boolean') {
      return {
        ...endpoint,
        url: `${endpoint.url}/${entity.id}/mark_read`,
        method: 'PUT',
        doesNeedId: false,
      }
      // Get Conversations
      // GET /employers/_/conversations
    } else {
      return endpoint
    }
  },

  // https://syftapp.atlassian.net/wiki/display/SV/Messaging+API+for+Clients
  message: (entity, meta, apiAction) => {
    const conversations = endpointWithEmployerBasePath('/conversations')(meta)
    const jobs = endpointWithEmployerBasePath('/jobs')(meta)
    const workers = endpointWithEmployerBasePath('/workers')(meta)

    if (apiAction === apiActions.create) {
      // Reply to an existing Conversation
      // POST /employers/_/conversations/_/messages
      if (!isNil(entity.threadId)) {
        return {
          ...conversations,
          url: `${conversations.url}/${entity.threadId}/messages`,
        }

        // Send message to Worker (possibly starting a new conversation)
        // with or without a job
        // POST /employers/_/jobs/_/workers/_/conversation/messages
        // POST /employers/_/workers/_/conversation/messages
      } else if (!isNil(entity.workerId)) {
        return {
          ...jobs,
          url: !isNil(entity.jobId)
            ? `${jobs.url}/${entity.jobId}/workers/${entity.workerId}/conversation/messages`
            : `${workers.url}/${entity.workerId}/conversation/messages`,
        }

        // Message all booked Workers
        // POST /employers/_/jobs/_/message_all_booked
      } else {
        return {
          ...jobs,
          url: `${jobs.url}/${entity.jobId}/message_all_booked`,
        }
      }
    } else {
      // Get Messages in a Conversation
      // GET /employers/_/conversations/_/messages
      return {
        ...conversations,
        url: `${conversations.url}/${entity.threadId}/messages`,
      }
    }
  },

  notification: (entity, meta, apiAction) => {
    const endpoint = endpointWithEmployerBasePath('/notifications')(meta)
    const markAsRead = typeof get(entity, 'read') === 'boolean'

    // Mark all notifications as read
    // See sagas/notification#markAllNotificationsAsRead
    // PUT /api/v2/employers/:employer_id/notifications/mark_read
    if (apiAction === apiActions.update && markAsRead && entity.id === '[all]') {
      return {
        ...endpoint,
        method: 'PUT',
        url: `${endpoint.url}/mark_read`,
        doesNeedId: false,
      }
    }

    // Mark a single notification as read
    // PUT /api/v2/employers/:employer_id/notifications/:notification_id/mark_read
    else if (apiAction === apiActions.update && markAsRead && entity.id) {
      return {
        ...endpoint,
        method: 'PUT',
        url: `${endpoint.url}/${entity.id}/mark_read`,
        doesNeedId: false,
      }
    }

    // GET /api/v2/employers/:employer_id/notifications
    else {
      return endpoint
    }
  },

  referralClaim: () => ({
    doesNeedId: false,
    method: 'POST',
    url: '/referral_claims',
  }),

  report: (entity, meta, apiAction) => {
    const employerId = get(meta, 'userData.employerId')

    if (includes([apiActions.create], apiAction)) {
      return { url: `/clients/${employerId}/report` }
    } else if (includes([apiActions.get], apiAction)) {
      return { url: `/clients/${employerId}/reports`, useBodyAsQueryParams: true }
    }

    throw new UnsupportedEntityEndpointError('clientReport', apiAction)
  },

  reportType: (entity, meta, apiAction) => {
    const employerId = get(meta, 'userData.employerId')

    if (includes([apiActions.list], apiAction)) {
      return { url: `/clients/${employerId}/reports` }
    }

    throw new UnsupportedEntityEndpointError('clientReportType', apiAction)
  },

  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/213647367/Bureau+Resources+API
  bureauResourcesCompany: (entity, meta, apiAction) => ({
    doesNeedId: false,
    method: 'GET',
    url: `/bureau_resources/search/companies`,
  }),

  timesheetFile: entity => ({
    doesNeedId: false,
    method: 'POST',
    url: `/imports/${entity.providerType}`,
  }),

  shiftBooking: entity => ({
    url: '/internal_resourcing/shift_bookings',
  }),

  paymentSession: (entity, meta, apiAction) => {
    if (apiAction === apiActions.create) {
      return {
        url: '/payments/checkout',
        method: 'POST',
        doesNeedId: false,
      }
    }
  },

  // CSAT
  csatSurvey: (entity, meta = {}, apiAction) => {
    // Fetching flag for showing CSAT
    const { employerId, initialCall, agencyId } = meta
    const currentAgencyId = get(meta, 'userData.agencyId') || agencyId
    if ((employerId || currentAgencyId) && initialCall) {
      return {
        url: employerId ? `/surveys/employers/${employerId}` : `/surveys/agencies/${currentAgencyId}`,
        doesNeedId: false,
      }
    }
    // Fetching data for reasons
    if (apiAction === apiActions.get) {
      return {
        url: '/surveys/employers/csat_reasons',
        doesNeedId: false,
      }
    }
    if (apiAction === apiActions.create) {
      const employerId = get(meta, 'userData.employerId')
      return {
        url: currentAgencyId
          ? `/surveys/agencies/${currentAgencyId}/csats`
          : `/surveys/employers/${employerId}/csats`,
        method: 'POST',
      }
    }
  },

  addressTimezone: () => ({
    url: '/address/geocoding/timezone',
    method: 'POST',
    doesNeedId: false,
  }),

  // Agency portal endpoints
  // TODO: wrap in agencyPortalEndpoint() decorator

  agencyClient: (_entity, meta) => endpointWithAgencyBasePath('/agency_clients')(meta),

  agencyAccount: (_entity, meta) => {
    const agencyId = get(meta, 'userData.agencyId')
    return { url: `/agency_portal/accounts/${agencyId}` }
  },

  agencyBranch: (_entity, meta) => {
    const agencyId = get(meta, 'userData.agencyId')
    return { url: `/agency_portal/branch_names/${agencyId}`, doesNeedId: false }
  },

  agencyAccountBranch: (_entity, meta, apiAction) => {
    if (apiAction === apiActions.update) {
      return { url: '/agency_portal/branches' }
    }

    return endpointWithAgencyBasePath('/branches')(meta)
  },

  agencyAccountVenue: (_entity, meta) => {
    const agencyId = get(meta, 'userData.agencyId')
    return { url: `/agency_portal/agencies/${agencyId}/venues_for_filters`, doesNeedId: false }
  },

  agencyAccountPermission: (_entity, meta) => {
    const accountId = get(_entity, 'accountId')
    const agencyId = get(meta, 'userData.agencyId')
    return { url: `/agency_portal/accounts/${agencyId}/permissions/${accountId}` }
  },

  agencyAccountDetail: (_entity, meta) => {
    return { url: '/agency_portal/bulk_agency_accounts', method: 'PATCH' }
  },

  agencyPayRate: (_entity, meta) => {
    return { url: '/agency_portal/agency_jobs/pay_rates' }
  },

  agencyAccountResendInvitation: (_entity, meta) => {
    const agencyAccountId = get(_entity, 'agencyAccountId')
    return { url: `/agency_portal/agency_accounts/${agencyAccountId}/resend_invitation`, method: 'PATCH' }
  },

  listingPayRate: (entity, meta) => {
    return { url: `/agency_portal/listings/${entity.listingUuid}/pay_rates` }
  },

  agencyProvisionalCost: (_entity, meta) => ({
    ...endpointWithAgencyBasePath('/provisional_cost')(meta),
    doesNeedId: false,
  }),

  // POST
  agencyListing: (_entity, meta) => {
    const { id, agencyListingUuid, uuid } = _entity || {}
    const effectiveId = id || agencyListingUuid || uuid
    const subpath = effectiveId ? `/listings/${effectiveId}` : '/listings'

    return {
      ...endpointWithAgencyBasePath(
        `${subpath}?lite=${!!meta.light}${effectiveId ? `&id=${effectiveId}` : ''}`,
        false,
      )(meta),
      // This condition is for agencyListing it is sharing logic for fetchEntity and fetchEntities
      // fetchEntity calls endpoint GET API with listingID and fetchCollection calls POST API
      // Make sure to add isGETMethod property to agencyListing if you want to use GET method
      method: !meta?.isGETMethod ? 'POST' : 'GET',
      useBodyAsQueryParams: meta?.isGETMethod,
      doesNeedId: false,
    }
  },

  agencyVenuesAndRole: (_entity, meta) => endpointWithAgencyBasePath('/venues_roles')(meta),

  agencyJob: (entity, meta) => {
    return {
      ...endpointWithAgencyBasePath('/agency_jobs')(meta),
      method: 'POST',
    }
  },
  agencyJobDetail: (entity, meta, apiAction) => {
    if (apiAction === apiActions.create) {
      return { url: '/agency_portal/jobs' }
    }
    return { url: `${endpointWithAgencyBasePath('/jobs')(meta).url}/${entity.id}`, doesNeedId: false }
  },

  agencyJobOverview: (entity, meta, apiAction) => {
    if (includes([apiActions.get], apiAction)) {
      return { url: `${endpointWithAgencyBasePath('/jobs')(meta).url}/${entity.id}`, doesNeedId: false }
    }

    throw new UnsupportedEntityEndpointError('agencyJobOverview', apiAction)
  },

  agencyCostCentreCode: (entity, meta) => {
    return {
      url: `/agency_portal/employers/${entity.employerId}/cost_centre_codes`,
      doesNeedId: false,
    }
  },

  agencyWorkerBooking: (entity, meta) => {
    return {
      ...endpointWithAgencyBasePath('/agency_bookings')(meta),
      doesNeedId: false,
    }
  },

  agencyListingJobCard: (entity, meta) => ({
    url: `${endpointWithAgencyBasePath('/listings')(meta).url}/${entity.listingUuid}/job_cards`,
    doesNeedId: false,
  }),

  agencyAllocation: (entity, meta) => ({
    url: `${endpointWithAgencyBasePath('/listings')(meta).url}/${entity.listingUuid}/allocations`,
  }),

  agencyAllocationWorker: ({ listingUuid, allocationId }, meta, apiAction) => {
    const baseUrl = endpointWithAgencyBasePath('/listings')(meta).url
    return meta?.copy && apiAction !== apiActions.delete && apiAction !== apiActions.update
      ? { url: `${baseUrl}/${listingUuid}/bulk_create_workers` }
      : { url: `${baseUrl}/${listingUuid}/allocations/${allocationId}/worker_details` }
  },

  agencyNotification: (entity, meta, apiAction) => {
    const endpoint = endpointWithAgencyBasePath('/notifications', true)(meta)
    const markAsRead = typeof get(entity, 'read') === 'boolean'
    // Mark all notifications as read
    // See sagas/notification#markAllNotificationsAsRead
    // PUT /api/v2/agency/:agency_id/notifications/mark_read
    if (apiAction === apiActions.update && markAsRead && entity.id === '[all]') {
      return {
        ...endpoint,
        method: 'PUT',
        url: `${endpoint.url}/mark_read`,
        doesNeedId: false,
      }
    }

    // Mark a single notification as read
    // PUT /api/v2/agency/:agency_id/notifications/:notification_id/mark_read
    else if (apiAction === apiActions.update && markAsRead && entity.id) {
      return {
        ...endpoint,
        method: 'PUT',
        url: `${endpoint.url}/${entity.id}/mark_read`,
        doesNeedId: false,
      }
    }

    // GET /api/v2/agency/:agency_id/notifications
    return endpoint
  },

  jobDurationPreview: (entity, meta, apiAction) => {
    if (apiAction === apiActions.create) {
      return {
        method: 'POST',
        ...endpointWithEmployerBasePath('/duration_preview')(meta),
      }
    }

    throw new UnsupportedEntityEndpointError('jobDurationPreview', apiAction)
  },

  enabledFeature: ({ isUnauthenticated }, meta, apiAction) => {
    if (apiAction === apiActions.get) {
      return {
        method: 'GET',
        doesNeedId: false,
        url: `${isUnauthenticated ? '/unauthenticated' : ''}/enabled_features`,
      }
    }
    throw new UnsupportedEntityEndpointError('enabledFeature', apiAction)
  },
  agencyJobShift: (_, meta, apiAction) => {
    if (apiAction === apiActions.update) {
      return { url: '/agency_portal/bulk_agency_shifts', doesNeedId: false }
    }
    return {
      ...endpointWithAgencyBasePath('/agency_shifts')(meta),
      doesNeedId: false,
    }
  },

  costCenterCode: (entity, { userData }) => {
    return { url: `/employers/${userData.employerId}/cost_centre_codes`, doesNeedId: false }
  },
  bankHolidayRegion: (_, { countryCode }) => {
    return {
      url: `/countries/${countryCode}/bank_holiday_regions`,
    }
  },
  offerConfig: (entity, meta) => ({
    url: `/listings/${meta.id}/offer_config`,
    doesNeedId: false,
  }),

  agencyAgentVenue: (entity, meta) => {
    const { employerId } = meta
    return {
      url: `/agency_portal/employers/${employerId}/agent_venues`,
      doesNeedId: false,
    }
  },
  employerManager: (entity, meta) => {
    const { employerId } = meta
    return {
      url: `/agency_portal/employers/${employerId}/employer_accounts`,
      doesNeedId: false,
    }
  },
  authAccountSetting: (entity, meta, apiAction) => {
    return {
      url: `/indeed_auth/account_settings`,
      doesNeedId: false,
    }
  },
  employerShiftRateType: (entity, meta, apiAction) => {
    if (meta.disableEnabled && apiAction === 'delete') {
      return {
        url: `${endpointWithEmployerBasePath('/shift_rate_types')(meta).url}/${entity.id}/disable`,
        method: 'PATCH',
        doesNeedId: false,
      }
    }
    return endpointWithEmployerBasePath('/shift_rate_types')(meta)
  },

  preferredAgencyWorker: (entity, meta, apiAction) => {
    if (apiAction === apiActions.delete) {
      return {
        url: `/agency_portal/preferred_agency_workers/${entity.id}`,
        doesNeedId: false,
      }
    }
    return {
      url: `/agency_portal/preferred_agency_workers`,
    }
  },
  scheduledAgency: (entity, meta, apiAction) => {
    return {
      url: `/listings/${meta.listingId}/scheduled_agencies`,
      doesNeedId: false,
    }
  },
  overtimeRule: (entity, meta, apiAction) => {
    return endpointWithEmployerBasePath('/overtime_rules')(meta)
  },
  bookingStat: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/booking_stats`,
      doesNeedId: false,
    }
  },
  totalWorkedHour: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/total_worked_hours`,
      doesNeedId: false,
    }
  },
  totalFilledShift: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/total_filled_shifts`,
      doesNeedId: false,
    }
  },
  staffAverageRating: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/staff_average_rating`,
      doesNeedId: false,
    }
  },
  venueAverageRating: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/venue_average_rating`,
      doesNeedId: false,
    }
  },
  totalEstimatedCost: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/estimated_cost`,
      doesNeedId: false,
    }
  },
  costBreakdown: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/cost_breakdown`,
      doesNeedId: false,
    }
  },
  workerNoShow: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/worker_no_shows`,
      doesNeedId: false,
    }
  },
  repeatStaff: (entity, meta, apiAction) => {
    return {
      url: `/employers/${entity.id}/repeat_staff`,
      doesNeedId: false,
    }
  },
  /** MARKER FOR ENDPOINT GENERATOR */
}

// TODO Refactor
const doesNeedIdentifier = (entityType, apiAction) =>
  !includes([apiActions.list, apiActions.create], apiAction)

/**
 * Returns API endpoint for entity type. Throws a RangeError for an invalid type.
 *
 * @param entityType {String} Singular entity type, e.g. 'worker' or 'listing'.
 *   See ./schemas/* for a list of available entity types
 * @param entity {Object} Entity data containing its identifier.
 *   Object of type `{ id: Number }`
 * @param apiAction {apiActions}
 * @param meta {Object}
 * @param meta.url {String} Optional, will override any computed URL
 */
export const getEntityEndpoint = (entityType, entity, apiAction, meta = {}) => {
  const entityWithType = { $type: entityType, ...entity }
  if (!includes(Object.keys(schemas), entityType)) {
    throw new RangeError('Invalid entity type')
  }
  const defaults = {
    url: `/${snakeCase(pluralize(entityType))}`,
    // TODO Remove entity.file condition
    method: get(entity, 'file') ? 'PUT' : methodForApiAction[apiAction],
    doesNeedId: doesNeedIdentifier(entityType, apiAction),
  }
  // TODO Don't return `doesNeedId`, return `url` and `baseUrl`
  const {
    url: baseEntityUrl,
    method,
    doesNeedId,
    useBodyAsQueryParams,
  } = {
    ...defaults,
    ...(customEndpoints[entityType] ? customEndpoints[entityType](entity, meta, apiAction) : {}),
    ...(meta.url ? { url: meta.url, doesNeedId: false } : {}),
  }

  if (doesNeedId && isEntityNew(entityWithType)) {
    throw new RangeError('Invalid ID')
  }
  const url = doesNeedId ? `${baseEntityUrl}/${idForEntity(entityWithType)}` : `${baseEntityUrl}`

  return { url, method, useBodyAsQueryParams }
}
